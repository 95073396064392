import React from "react"
import main from "../images/main.jpg"
import "../App.css"
import { Avatar } from "@material-ui/core"

export default function Home() {
  return (
    <header className="App-header">
      <h2>Welcome to Steve V's Site</h2>

      {/* <Avatar className="main-avatar" alt="Steve V Picture" src={main} /> */}
    </header>
  )
}
